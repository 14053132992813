<template>
    <div class="container">
        <h5 class="text-center font-proxima-bold">Subscription Plans</h5>
        <p class="text-center pb-4">Choose the plan that is best for you</p>
        <div class="row">
            <div class="col-lg-4">
                <PaymentCard header="Basic Plan" subHeader="₹1/month">
                    <template v-slot:btn-content>
                        <div class="fl-x-cc translate-y-10r pb-4">
                            <btn color="eighth" design="basic-a" class="px-3 text-primary-600 font-weight-500" border-radius="1">Continue</btn>
                        </div>
                    </template>
                </PaymentCard>
            </div>
            <div class="col-lg-4">
                <PaymentCard header="Basic Plan" subHeader="₹150/3 months">
                    <template v-slot:btn-content>
                        <div class="fl-x-cc translate-y-10r pb-4">
                            <btn color="eighth" design="basic-a" class="px-3 text-primary-600  font-weight-500" border-radius="1">Continue</btn>
                        </div>
                    </template>
                </PaymentCard>
            </div>
            <div class="col-lg-4">
                <PaymentCard header="Standard Plan" subHeader="₹10/1 months">
                    <template v-slot:card-content>
                        <div class="translate-y-40p">
                            <p class="text-center">You will save: 50%</p>
                            <p class="text-center">You will save: 40%</p>
                        </div>
                    </template>
                    <template v-slot:btn-content>
                        <div class="fl-x-cc translate-y-5r pb-4">
                            <btn color="eighth" design="basic-a" class="px-3 text-primary-600 font-weight-500" border-radius="1">Continue</btn>
                        </div>
                    </template>
                </PaymentCard>
            </div>
            <div class="col-lg-4 mt-2">
                <PaymentCard header="Premium Plan" subHeader="₹260/month">
                    <template v-slot:badge>
                        <img src="../../../assets/img/premium.png" class="img-fluid pr-1 h-5vh translate-y--30p">
                    </template>
                    <template v-slot:btn-content>
                        <div class="fl-x-cc translate-y-10r pb-4">
                            <btn color="eighth"  class="px-3 text-primary-600  font-weight-500" border-radius="1">Continue</btn>
                        </div>
                    </template>
                </PaymentCard>
            </div>

        </div>
    </div>
</template>

<script>
import PaymentCard from '@components/cards/PaymentCard';

export default {
    name: 'SubscriptionPlan',
    components: { PaymentCard }
};
</script>

<style>
</style>
